.flex {
     display: flex;
}

.flex-col {
     flex-direction: column;
}

.flex-row {
     flex-direction: row;
}