.slide-in-left {
	-webkit-animation: slide-in-left 2s cubic-bezier(0.895, 0.030, 0.685, 0.220) forwards;
	animation: slide-in-left 2s cubic-bezier(0.895, 0.030, 0.685, 0.220) forwards;
}

@keyframes slide-in-left {
     0% {
          -webkit-transform: translateX(-4rem);
          transform: translateX(-4rem);
          opacity: 0;
     }
     100% {
          -webkit-transform: translateX(0);
          transform: translateX(0);
          opacity: 1;
     }
}

.slide-in-bottom-right {
	-webkit-animation: slide-in-bottom-right 3s cubic-bezier(0.645, 0.045, 0.355, 1.000) forwards;
     animation: slide-in-bottom-right 3s cubic-bezier(0.645, 0.045, 0.355, 1.000) forwards;
}

@keyframes slide-in-bottom-right {
     0% {
          -webkit-transform: translate(4rem, -4rem);
          transform: translateY(4rem, -4rem);
          opacity: 0;
     }

     50% {
          -webkit-transform: translate(2rem, -2rem);
          transform: translateY(2rem, -2rem);
          opacity: 0;
     }

     100% {
          -webkit-transform: translateY(0);
          transform: translateY(0);
          opacity: 1;
     }
}

.slide-out-bck-center {
	-webkit-animation: slide-out-bck-center 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
     animation: slide-out-bck-center 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

@-webkit-keyframes slide-out-bck-center {
     0% {
       -webkit-transform: translateZ(0);
               transform: translateZ(0);
       opacity: 1;
     }
     100% {
       -webkit-transform: translateZ(-1100px);
               transform: translateZ(-1100px);
       opacity: 0;
     }
}

@keyframes slide-out-bck-center {
     0% {
       -webkit-transform: translateZ(0);
               transform: translateZ(0);
       opacity: 1;
     }
     100% {
       -webkit-transform: translateZ(-1100px);
               transform: translateZ(-1100px);
       opacity: 0;
     }
}

.slide-in-top {
	-webkit-animation: slide-in-top 0.6s cubic-bezier(0.645, 0.045, 0.355, 1.000) ;
	animation: slide-in-top 0.6s cubic-bezier(0.645, 0.045, 0.355, 1.000) ;
}

@-webkit-keyframes slide-in-top {
     0% {
       -webkit-transform: translateY(-2rem);
               transform: translateY(-2rem);
       opacity: 0;
     }
     100% {
       -webkit-transform: translateY(0);
               transform: translateY(0);
       opacity: 1;
     }
   }
   @keyframes slide-in-top {
     0% {
       -webkit-transform: translateY(-2rem);
               transform: translateY(-2rem);
       opacity: 0;
     }
     100% {
       -webkit-transform: translateY(0);
               transform: translateY(0);
       opacity: 1;
     }
}