.themeIcon {
     color: $primary-color;
     font-size: $lg;

     &:hover {
          color: $primary-color-light;
     }
};

.themeIconMenu {
     font-size: $xxl;
     color: $primary-color;

     &:hover {
          color: $primary-color-light;
     }
}

.themeIconMenuLightGrey {
     font-size: $xxl;
     color: $secondary-color-light;

     &:hover {
          color: $primary-color;
     }
}

.largeIconRed {
     font-size: $xxxl;
     color: $primary-color;
}

.largeIconWhite {
     font-size: $xxxl;
     color: $secondary-color;
}

.smallIconRed {
     color: $primary-color;
     font-size: $md;
}

.smallIconwhite {
     color: $secondary-color;
     font-size: $md;

     &:hover {
          color: $primary-color;
     }
}
