.grid {
     display: grid;
}

// TEMPLATES
// COLUMNS
.gr-t-c2 {
     grid-template-columns: 1fr 1fr;
}

.gr-t-c2-25-75 {
     grid-template-columns: 25% 75%;
}

.gr-t-c2-40-60 {
     grid-template-columns: 40% 60%;
}

.gr-t-c3 {
     grid-template-columns: repeat(auto-fit, minmax(30rem, 28%));

     @include breakpoint(lg) {
          grid-template-columns: 90%;
     }

     @include breakpoint(sm) {
          grid-template-columns: 1fr;
     }
}

.gr-minmax {
     grid-template-columns: minmax(70%, 1fr) auto;
}

.gr-t-c-auto {
     grid-template-columns: auto;
}
// ROWS
.gr-t-r-auto {
     grid-template-rows: max-content;
}

// JUSTIFY
.jus-sp-bt {
     justify-content: space-between;
}

.jus-sp-around {
     justify-content: space-around;
}

.jus-end {
     justify-content: end;
}

.jus-start {
     justify-content: start;
}

.jus-c {
     justify-content: center;
}

.jus-it-c {
     justify-items: center;
}

// GAP - SPACING
.gap-1 {
     grid-gap: 1rem;
}

.gap-2 {
     grid-gap: 2rem;
}

// ALIGN

.align-c {
     align-items: center;
}

// GRID ITEMS
.gr-col-full {
     grid-column: 1 / -1;
}



     


