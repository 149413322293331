.heroBg {
     transform: translatey(-10rem);
     width: 100%;
     height: 80vh;
     position: relative;

     & img {
          width: 100%;
          height: 100%;
          object-fit: cover;   
     }

     &__contentBox {
          position: absolute;
          top: 50%;
          left: 2rem;
          z-index: 100;
     }

     &__title {
          text-transform: capitalize;
          font-size: $xxl;
          color: $secondary-color;
          padding: 0 1rem;
     }

     & span,
     & a {
          padding: .5rem .5rem;
          color: $secondary-color;
          font-family: $secondary-font;
          font-size: $md;
          font-weight: 300;
          flex-wrap: wrap;
     }

     & a:hover {
          color: $primary-color;
     }

     &__overlay {
          width: 100%;
          height: 100%;
          position: absolute;
          background-color: rgba(0, 0, 0, .3);
          top: 0;
          left: 0;
     }
}