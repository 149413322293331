@import url('https://fonts.googleapis.com/css2?family=Arvo:ital,wght@0,400;0,700;1,400;1,700&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,300&display=swap');

html {
     font-size: 62.5%;
}

$xs: .8rem;
$sm: 1.2rem;
$md: 1.6rem;
$lg: 2rem;
$xl: 2.4rem;
$xxl: 3rem;
$xxxl: 5rem;

$primary-font: 'Montserrat', sans-serif;
$secondary-font: 'Roboto', sans-serif;

h1, h2, h3,
h4, h5, h6 {
     font-family: $primary-font;
}

p, span, div {
     font-family: $secondary-font;
     line-height: 1.5;
}

strong {
     font-weight: 800;
}