.infoCard {
     width: 100%;
     height: 20rem;
     border-radius: .5rem;
     -webkit-box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.06); 
     box-shadow: 5px 5px 15px 5px #0000000f;
     grid-template-rows: 40% 5% 55%;

     &:first-child {
          background-color: $primary-color;
          color: $secondary-color;
     }

     &:nth-child(2) {
          background-color: $secondary-color-dark;
          color: $secondary-color;
     }

     &:nth-child(3) {
          background-color: $secondary-color;
     }

     & h3 {
          width: 100%;
          justify-self: flex-start;
          font-size: $xl;
          font-family: $secondary-font;
          font-weight: 300;
     }

     & p {
          width: 100%;
          display: flex;
          align-self: flex-start;
          padding: 0 2rem;
          font-size: $md;
          font-family: $secondary-font;
          font-weight: 300;
     }
}

.hr-white,
.hr-black {
     width: 90%;
     height: 1px;
     border: none; 
}

.hr-white {
     background-color: rgba(255, 255, 255, .2);
}

.hr-black {
     background-color: rgba(0, 0, 0, .2);
}