.satisfactoryBlock {
     width: 50%;
     height: 15rem;

     @include breakpoint(lg) {
          width: auto;
     }

     @include breakpoint(sm) {
          height: 20rem;
          padding: 2rem;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;

     }

     & div {
          margin: 0 2rem;

          @include breakpoint(sm) {
               margin: 1rem 0;
          }
     }

     & p {
          font-family: $secondary-font;
          font-size: $md;
          font-weight: 500;
          color: $secondary-color;
          margin-left: 1rem;
     }
}