.contact {
     margin: 5rem 0;

     &__img {
          width: 100%;
          height: auto;

          @include breakpoint(lg) {
               grid-row-start: 1;
          }
     }
}