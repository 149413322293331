.contactCard {
     width: 100%;
     height: 25rem;
     cursor: pointer;
     overflow: hidden;
     border-radius: .5rem;
     -webkit-box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.06); 
     box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.06);

     &__iconBox {
          width: 10rem;
          height: 10rem;
          background-color: $primary-color;
          border-radius: 100%;
     }

     &__titleBox {
          font-family: $primary-font;
          font-size: $xl;
          font-weight: 300;
          color: $secondary-color-dark;
     }
     
     &__contentBox {
          font-family: $primary-font;
          font-size: $md;
          font-weight: 900;
          color: $secondary-color-dark;
     }
}