.infoLine {
     height: 6rem;
     background-color: $secondary-color-light;
     z-index: 10;

     @include breakpoint(md){
          height: 4rem;
     }

     @include breakpoint(xl){
          display: none;
     }

     &__section1 {
          padding: 0 2rem;
     }

     &__address p,
     &__email p {
          font-size: $sm;
          font-family: $primary-font;
          font-weight: 700;
     }
     
     &__request {
          font-size: $md;
          font-weight: 500;
          font-family: $primary-font;
          padding: 0 5rem;
          background-color: $primary-color;
          
          & p {
               color: $secondary-color;
          }
     }

     &__address,
     &__email,
     &__request,
     &__social {
          cursor: pointer;
     }
}