.assessmentCard {
     width: 100%;
     background-color: $secondary-color;
     transform: translateY(-12.5rem);
     border-radius: .5rem;
     overflow: hidden;
     -webkit-box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.06); 
     box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.06);

     @include breakpoint(lg) {
          grid-template-columns: 1fr;
     }

     &__imgBox {
          width: 100%;
          height: 25rem;

          @include breakpoint(lg) {
               height: auto;
          }
          
          & img {
               width: 100%;
               height: 100%;
               object-fit: cover;
          }
     }

     &__contentBox {
          padding: 2rem;
          &-title,
          &-body {
               color: $secondary-color-dark;
          }

          &-title {
               font-size: $xxl;
               font-family: $primary-font;
               font-weight: 700;
          }

          &-body {
               font-size: $lg;
               font-family: $secondary-font;
               font-weight: 500;
               line-height: 1.5;
          }
     }

}