.contactForm {
     position: relative;
     width: 100%;
     min-height: 30rem;
     height: auto;
     margin: 5rem 0;
     padding: 3rem;
     border-radius: .5rem;
     -webkit-box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.06); 
     box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.06);
     overflow: clip;
     background-color: $secondary-color;

     &__title {
          font-size: $lg;
          font-weight: 300;
     }

     &__inputsBox {
          display: grid;
          grid-template-columns: 1fr;
          gap: 1rem;
          @include breakpoint(md) {
               grid-template-columns: 1fr;
          }
     }

     &__error {
          margin: .3rem 0;
          color: $primary-color;
     }

     &__input{
          width: 100%;
          max-height: 4rem;
          display: block;
          padding: 1rem;
          outline: none;
          border: 1px solid $primary-color-light;
          border-radius: .5rem;
          font-size: $md;

          &::placeholder {
               color: $secondary-color-light;
               text-transform: uppercase;
               font-size: $sm;
          }

          &:focus {
               border: 1px solid $primary-color;
          }

     }
     
     &__inputError {
          background-color: $primary-color;
          color: $secondary-color;
     }

     &__success {
          color: $primary-color-success;
          font-size: $sm;
          font-weight: 500;
     }
     
     &__textarea {
          width: 100%;
          display: block;
          padding: 1rem;
          outline: none;
          border: 1px solid $primary-color-light;
          border-radius: .5rem;
          font-size: $md;
          resize: vertical;

          &::placeholder {
               color: $secondary-color-light;
               text-transform: uppercase;
               font-size: $sm;
          }

          &:focus {
               border: 1px solid $primary-color;
          }
     }

     &__button {
          min-width: 50%;
          max-height: 4rem;
          display: block;
          padding: 1rem;
          outline: none;
          border-radius: .5rem;
          font-size: $md;
          border: none;
          background-color: $primary-color;
          color: $secondary-color;
          cursor: pointer;
          text-align: center;
     }
}

.PhoneInputInput {
          padding: 1rem;
          outline: none;
          border: 1px solid $primary-color-light;
          border-radius: .5rem;
          font-size: $md;

          &::placeholder {
               color: $secondary-color-light;
               text-transform: uppercase;
               font-size: $sm;
          }

          &:focus {
               border: 1px solid $primary-color;
          }
}

.PhoneInputCountrySelect option {
     padding: 1rem !important;
}

.contactForm__PhoneError input.PhoneInputInput {
     background-color: $primary-color;
     color: $secondary-color;
}