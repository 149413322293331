.white-bg {
     width: 100%;
     max-width: 100%;
     background-color: $secondary-color;
}

.dark-bg {
     width: 100%;
     max-width: 100%;
     background-color: $secondary-color-dark;
}

.grey-bg {
     width: 100%;
     max-width: 100%;
     background-color: $secondary-color-light;
}