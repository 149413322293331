.testimonialsBlock {
     position: relative;
     width: 100%;
     height: min-content;
     
     @include breakpoint(lg) {
          grid-template-columns: 1fr;
     }
     
     @include breakpointMin(xxl) {
          padding: 0 7vw;
     }
     
     &__leftBox {
          padding: 2rem;
          padding-left: 10rem;     
          @include breakpoint(lg) {
               padding-left: 2rem;
          }
     }

     &__title {
          font-size: $md;
          font-family: $secondary-font;
          font-weight: 900;
          color: rgba(0, 0, 0, .1);
          text-transform: uppercase;
     }

     &__subtitle {
          font-size: $xxl;
          font-family: $primary-font;
          font-weight: 200;
          line-height: 1.5;

          & span {
               font-size: $xxl;
               font-weight: 800;
          }
     }

     &__rightBox {
          width: 100%;
          height: auto;
     }

     &__imgBox {
          width: 100%;
          height: 100%;

          & img {
               width: 100%;
               height: 100%;
               object-fit: cover;
               transform: translateY(10rem);
               border-top-left-radius: .5rem;
               border-bottom-left-radius: .5rem;
               -webkit-box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.06); 
               box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.06);
               
               @include breakpointMin(xxl) {
                    border-radius: .5rem;
               }
               
               @include breakpoint(lg) {
                    transform: translate(0);
               }
          }

     }

     &__stats {
          transform: translateY(10rem);
     }



}

.testimonialsSlider {
     width: 100%;
     height: 23rem;
}

.testimonial {

     &__name,
     &__country,
     &__body {
          font-family: $primary-font;
          font-size: $md;
          color: $secondary-color-dark;
     }

     &__name {
          font-weight: 800;
     }

     &__country {
          font-weight: 500;
     }

     &__body {
          line-height: 1.5;
     }
}