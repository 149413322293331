.optionSlider {
     width: 95%;
     height: 35rem;

     @include breakpoint(sm) {
          padding: 2rem;
          width: 100%;
     }

     &__slide {
          width: 100%;
          display: flex;
          justify-content: center;
     }
}

.swiper-pagination-bullet-active {
     background-color: $primary-color !important;
}

.optionSlide {
     width: 25rem;
     height: 25rem;
     border-radius: .5rem;
     -webkit-box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.2); 
     box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.2);

     & h3 {
          font-size: $lg;
          font-family: $secondary-font;
          font-weight: 700;
          text-align: center;
          padding: 1rem;
          color: $secondary-color;
     }

     & div {
          justify-self: center;
          width: 8rem;
          height: 8rem;
          border-radius: 100%;
          background-color: $primary-color;
     }

     & p {
          font-size: $md;
          font-family: $secondary-font;
          font-weight: 500;
          text-align: center;
          line-height: 1.5;
          padding: 0 1rem;
          color: $secondary-color-light;
     }
}