.mobile {
     width: 100vw;
     max-width: 100%;
     height: 10rem;
     position: absolute;
     top: 0;
     left: 0;
     color: white;
     font-family: $primary-font;
     z-index: 10;

     &__iconBox {
          position: absolute;
          top: 2.6rem;
          right: 3rem;
          background-color: $primary-color;
          border-radius: 100%;

          @include breakpoint(sm) {
               transform: scale(0.9) !important;
          }
     }

     &__menu {
          width: 95%;
          background-color: $primary-color;
     }

     &__logo {
          position: absolute;
          top: 1rem;
          left: 3rem;
          width: auto;
          height: 8rem;
     }
}

.navMobile {
     width: 90%;
     background-color: $secondary-color;
     color: $secondary-color-dark;
     border-top: 1px solid $primary-color-light;
     
     &__active {
          position: absolute;
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
          opacity: 1;
     }

     &__notActive {
          opacity: 0;
     }

     &__menu {
          &:not(:last-child) {
               border-bottom: 1px solid $secondary-color-dark;
          }
     }

     &__item,
     &__subitem {
          width: 90vw;
          padding: 2rem;
          transition: all linear .2s;
          font-family: $primary-font;
          font-size: $sm;
          font-weight: 600;
          text-transform: uppercase;
          cursor: pointer;
          
          a {
               color: $primary-color;
          }
     }

     &__subitem {
          
          & * {
               padding: 0 .5rem;
          }
     }
}