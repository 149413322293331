$breakpoints: (
  sm: 480px,
  md: 720px,
  lg: 960px,
  xl: 1200px,
  xxl: 1440px
);

@mixin breakpoint($name) {
  @if not map-has-key($breakpoints, $name) {
    @warn "Warning: `#{$name}` is not a valid breakpoint name.";
  } @else {
    @media (max-width: map-get($breakpoints, $name)) {
      @content;
    }
  }
}

@mixin breakpointMin($name) {
     @if not map-has-key($breakpoints, $name) {
       @warn "Warning: `#{$name}` is not a valid breakpoint name.";
     } @else {
       @media (min-width: map-get($breakpoints, $name)) {
         @content;
       }
     }
   }