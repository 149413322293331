.headerSlider {
     transform: translatey(-10rem);
     width: 100%;
     max-height: 110vh;
     z-index: -1;
     
     &__slide {
          position: relative;

          &::before {
               content: '';
               width: 100%;
               height: 100%;
               position: absolute;
               background-color: rgba(0, 0, 0, .3);
               z-index: 1;
          }

          & div {
               position: relative;
               width: 100%;
               height: 100%;

               & .sliderTitle {
                    z-index: 100;
                    position: absolute;
                    top: 40%;
                    left: 10%;
                    font-family: $secondary-font !important;
               
                    & h1 { 
                         font-size: $md;
                         text-transform: uppercase;
                         color: $secondary-color;
                         font-weight: 600;
                         letter-spacing: .5rem;
                    }
               
                    & h2 {
                         padding: 2rem 0;
                         font-size: 7rem;
                         font-weight: 300;
                         letter-spacing: -.5rem;
                         color: $secondary-color;
                    }
               
                    p {
                         font-size: 7rem;
                         font-weight: 800;
                         color: $secondary-color;
                    }

                    @include breakpoint(lg) {
                         & h1 { font-size: $sm; }
                         & h2, & p { font-size: $xxxl } 
                    }

                    @include breakpoint(md) {
                         & h1 { font-size: $xs; }
                         & h2, & p { 
                              font-size: $xxl; 
                              letter-spacing: 0; 
                         } 
                    }

                    @include breakpoint(md) {
                         & h1 { display: none; }
                         & h2, & p { 
                              font-size: $xl; 
                              letter-spacing: 0; 
                         } 
                         & h2 {
                              padding: 1rem 0;
                         }
                    }
               }
          }

          & img {
               width: 100%;
               height: 100%;
               object-fit: cover;
          }
     }
}