.textCard {
     padding: 2rem;

     & h2 {
          font-weight: 300;
          font-size: $xxl;
     }

     & h3 {
          font-weight: 300;
          font-size: $xl;
     }

     & p {
          text-align: justify;
          font-size: $md;
          font-weight: 700;
          color: $secondary-color-dark;
     }
     ul {
          list-style: disc;
          padding: 2rem;
     }

     li {
          font-size: $md;
          font-weight: 300;

          & ul {
               padding: 2rem;
               list-style: circle;

               & li {
                    
                    & ul {
                         padding: 2rem;
                         list-style: square;  
                    }
               }
          }
     }

     & a {
          color: $primary-color;
     }
}