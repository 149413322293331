.themeButton {
     width: auto;
     height: min-content;
     padding: 2rem;
     border-radius: .5rem;
     background-color: $primary-color;
     cursor: pointer;
     -webkit-box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.2); 
     box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.2);

     &__body {
          font-family: $secondary-font;
          font-size: $md;
          color: $secondary-color;
     }

     &:active {
          transform: scale(0.99);
          -webkit-box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.3); 
          box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.3);
     }
}