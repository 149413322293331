body {
     max-width: 100vw;
     width: 100%;
     height: auto;
     position: relative;
}

.themeLayout {
     position: relative;
     width: 100%;
}

.padding-bottom25 {
     padding-bottom: 25rem;
}