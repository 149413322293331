.imageCard {
     width: 100%;
     padding-top: 40rem;
     border-radius: .5rem;
     overflow: hidden;
     
     &__img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          margin-top: -40rem;
     }

     @include breakpoint(lg) {
          padding-top: 0;

          &__img {
               margin-top: 0;
          }
     }
}