.aboutBlock {
     width: 100%;
     padding-top: 6rem;

     &__default-text {
          font-size: $md;
          font-family: $secondary-font;
          font-weight: 900;
          color: rgba(0, 0, 0, .1);
          text-transform: uppercase;
     }

     &__text,
     &__bullets {
          font-size: $md;
          font-family: $secondary-font;
          font-weight: 700;
          line-height: 3rem;
          color: $secondary-color-dark;
          margin-top: .5rem;
     }

     &__bullets {
          font-weight: 300 !important;
          & * {
               padding: 0 .3rem;
          }
     }


     & div {
          padding: 2rem;

          

          & h2 {
               font-size: $xxl;
               font-family: $primary-font;
               font-weight: 200;
               line-height: 1.5;

               & span {
                    font-size: $xxl;
                    font-weight: 800;
               }
          }

          h3 {
               font-size: $lg;
               font-family: $secondary-font;
               font-weight: 300;
               line-height: 2.5rem;
               color: $secondary-color-dark;
          }
     }
}