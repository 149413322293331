.aboutCard {
     margin: 5rem 0;

     @include breakpointMin(xxl) {
          padding: 3rem 10%;
     }
     
     @include breakpoint(lg) {
          grid-template-columns: 1fr;
     }

     @include breakpoint(sm) {
          padding: 0 2rem;
     }

     &__baseText {
          font-size: $md;
          font-family: $secondary-font;
          font-weight: 900;
          color: rgba(0, 0, 0, .1);
          text-transform: uppercase;

     }

     & h3 {
          font-size: $xxl;
          font-family: $primary-font;
          font-weight: 200;
          line-height: 1.5;

          & span {
               font-size: $xxl;
               font-weight: 800;
          }
     }

     &__text {
          font-size: $md;
          font-family: $secondary-font;
          font-weight: 700;
          line-height: 3rem;
          color: $secondary-color-dark;
          margin-top: 2rem;

          @include breakpoint(lg) {
               margin-top: 3rem;
          }
     }
}