.doubleImgBlock {
     width: 100%;
     height: 55vw;
     padding: 6rem 0;
     position: relative;
     overflow: clip;

     @include breakpoint(lg) {
          height: 100vw;
     }

     @include breakpoint(sm) {
          height: 110vw;
     }

     & div:first-child {
          position: relative;
          padding-top: calc((1 / 1) * 100%);
          height: 0;
          overflow: hidden;
          
          & img {
               position: absolute;
               top: 0;
               left: 0;
               width: 80%;
               height: 80%;
               border-radius: .5rem;
               -webkit-box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.06); 
               box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.06);
               
               @include breakpoint(lg) {
                    left: 5%;
               }
          }
          
     }

     & div:last-child {
          position: relative;
          padding-top: calc((2 / 3) * 100%);
          height: 0;
          overflow: hidden;
          transform: translateY(-70%) translateX(30%);
          @include breakpoint(lg) {
               transform: translateY(-70%) translateX(35%);
          }

          & img {
               position: absolute;
               top: 0;
               left: 0;
               width: 60%;
               height: 60%;
               border-radius: .5rem;
               -webkit-box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.06); 
               box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.06);
          }
     }
}