.assessmentForm {
     
     &__iconBox {
          position: absolute;
          top: .6rem;
          right: 3rem;
          border-radius: 100%;
          
          @include breakpoint(sm) {
               transform: scale(0.7) !important;
          }
     }
     
     &__form {
          grid-template-columns: 60%;
          padding: 10rem 0;
          @include breakpoint(md) {
               grid-template-columns: 1fr;
               padding: 10rem 2rem;
          }
     }
     
     &__input,
     &__textarea {
          width: 100%;
          max-height: 4rem;
          display: block;
          padding: 1rem;
          outline: none;
          border: 1px solid $primary-color-light;
          border-radius: .5rem;
          color: $secondary-color-dark;
          font-size: $sm;
          font-weight: 700;

          &::placeholder {
               color: $secondary-color-dark;
               text-transform: uppercase;
               font-size: $sm;
          }

          &:focus {
               border: 1px solid $primary-color;
          }
     }

     & input:focus {
          border: 1px solid $primary-color;
     }

     &__textarea {
          max-height: 100%;
          resize: vertical;
     }

     &__fieldset {
          border: 1px solid $primary-color-light;
          padding: 2rem;

          & legend {
               color: $secondary-color-dark;
               font-size: $sm;
               font-weight: 700;
          }
     }

     &__label {
          color: $secondary-color-dark;
          font-size: $sm;
          font-weight: 700;
     }
     
     &__checkbox {
          position: relative;
          width: 2rem;
          height: 2rem;
          border: 1px solid $primary-color-light;
          margin-right: 1rem;
          border-radius: .3rem;

          &:checked {
               border: 1px solid $primary-color-light;

               &:after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 1.5rem;
                    height: 1.5rem;
                    border-radius: .3rem;
                    background-color: $primary-color;
               }
          }
     }

     &__button {
          width: 50%;
          max-height: 4rem;
          display: block;
          padding: 1rem;
          outline: none;
          border-radius: .5rem;
          font-size: $md;
          border: none;
          background-color: $primary-color;
          color: $secondary-color;
          cursor: pointer;
     }

     &__inputError {
          background-color: $primary-color;
          color: $secondary-color;
     }

     &__error {
          margin: .3rem 0;
          color: $primary-color;
     }

     &__success {
          color: $primary-color-success;
          font-size: $sm;
          font-weight: 500;
     }
}

.assessmentForm__PhoneError input.PhoneInputInput {
     background-color: $primary-color;
     color: $secondary-color;
}