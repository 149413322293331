.main {
     background-color: $secondary-color-light;

     &__section-one {
          padding: 2rem 0;
          transform: translateY(-10rem);

          @include breakpoint(lg) {
               transform: translate(0);
          }
     }

     &__section-two,
     &__section-three {
          @include breakpoint(lg) {
               grid-template-columns: 1fr;
          }
     }

     &__section-three {
          width: 100%;
          color: $secondary-color;

          & .aboutCard__baseText {
               color: rgba(255, 255, 255, .4);
          }

          & .aboutCard__text {
               color: $secondary-color-light;
          }

          
     }
}