.footer {

     &__contentBox {
          @include breakpoint(lg) {
               justify-content: center;
               align-items: center;
          }
     }

     &__padding {
          padding: 5rem 0;
     }

     &__title,
     &__text,
     &__link {
          color: $secondary-color-light;
          font-size: $md;
          font-weight: 500;
     }

     &__text {
          @include breakpoint(lg) {
               text-align: center;
               padding: 0 2rem;
          }
     }

     &__title {
          text-transform: uppercase;
          margin-bottom: 1rem;
     }
     
     &__link {
          font-size: $sm;
          cursor: pointer;
          transition: all .3s linear;
     }

     &__link {
          text-transform: uppercase;

          &:not(:last-child) {
               margin-bottom: 1rem;
          }
     }

     &__link:hover {
          color: $primary-color;
     }
     
     &__imgBox {
          width: 100%;
          height: 100%;
          cursor: pointer;

          @include breakpoint(lg) {
               justify-content: center;
               align-items: center;
          }
     }

     &__logo {
          width: 15rem;
          height: auto;
     }

     &__iconsBox {

          & a {
               border: 1px solid $primary-color;
               padding: 1.5rem;
          }

          & a:not(:first-child) {
               margin-left: 2rem;
          }

          @include breakpoint(lg) {
               justify-content: center;
               align-items: center;
          }
     }

     & hr {
          height: 0;
          border: 1px solid rgba(255, 255, 255, .1);
          
     }
}