.logo {
     width: 10rem;
     height: auto;
     display: flex;
     align-items: center;
     margin-right: 2rem;

     & img {
          width: 100%;
          height: 100%;
          object-fit: cover;
     }
}