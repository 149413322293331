.pageList {
     border-radius: .5rem;
     overflow: hidden;
     cursor: pointer;
     -webkit-box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.06); 
     box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.06);

     &__item {
          background-color: $secondary-color-light;
          color: $secondary-color-dark;
          padding: 2rem;
          font-family: $secondary-font;
          font-size: $sm;
          font-weight: 600;
          transition: all .3s linear;
          
          &:hover {
               background-color: $primary-color;
               color: $secondary-color;
          }

          &-active {
               background-color: $primary-color;
               color: $secondary-color;
          }
     }

     &__item:not(:last-child) {
          border-bottom: 1px solid $secondary-color-dark;
     }

}