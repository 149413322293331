.themePageLayout {
     padding: 5vw;
     display: grid;
     grid-template:
          'menu content content content' 
     ;
     gap: 2rem;

     @include breakpoint(lg) {
          grid-template: 
               'content'
               'menu'
          ;
     }
}

.col-1 {
     min-width: 25rem;
     grid-area: menu;

     @include breakpoint(lg) {
          min-width: auto;
     }
}

.col-2 {
     min-width: 60rem;
     grid-area: content content;

     @include breakpoint(lg) {
          min-width: auto;
     }
}