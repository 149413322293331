$primary-color: rgb(219, 6, 34);
$primary-color-light: rgb(216, 6, 33, .3);
$primary-color-dark: rgb(112, 2, 17);
$primary-color-success: rgb(74, 181, 66);

$secondary-color: #ffffff;
$secondary-color-light: rgb(223, 223, 223);
$secondary-color-mid: rgb(174, 174, 174);
$secondary-color-dark: rgb(79, 79, 79);


