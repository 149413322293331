.desktop {
     height: 10rem;
     padding: 0 2rem;
     border-bottom: 1px solid $secondary-color-light;
     font-family: $primary-font;
     z-index: 10;

     &__section1,
     &__section2 {
          cursor: pointer;
          height: 100%;
     }

     &__contact p {
          padding: .2rem;
          font-size: $md;
          font-weight:600;
          color: $secondary-color;
     }
}

.nav span {
     position: relative;
     padding: 0 .5rem;
     font-size: $sm;
     font-weight: 600;
     text-transform: uppercase;

     & a {
          color: $secondary-color;
          
          &:hover {
               color: $primary-color;
          }
     }
}

.subNav {
     position: absolute;
     height: auto;
     top: 100%;
     left: .5rem;
     background-color: $secondary-color;
     border-top: 2px solid $primary-color-light;

     &__subitem {
          width: 30rem;
          padding: 2rem;
          transition: all linear .3s;
          color: $primary-color !important;

          &:hover {
               background-color: $primary-color;
               color: $secondary-color !important;
          }

          &:not(:last-child) {
               border-bottom: 1px solid $secondary-color-dark;
          }
          
     }
}

.desktop-fixed {
     width: 100%;
     height: 6rem;
     position: fixed;
     top: 0;
     left: 0;
     background-color: $secondary-color-light;
     transition: all .4s linear;

     & a,
     & p {
          color: $secondary-color-dark !important;

          &:hover {
               color: $secondary-color !important;
          }
          
     }
     
     &__logo {
          width: 6rem;
     }
}